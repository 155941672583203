import React from 'react';
import { Translate } from 'src/components/Translate';
import membershipBenefits, { BENEFITS_SCENE } from 'src/helpers/membershipBenefits';

type RenewMembershipDetailsProps = {
  level: number;
  price: number;
};

const RenewMembershipDetails: React.FC<RenewMembershipDetailsProps> = ({ level, price }) => {
  const benefits = membershipBenefits(level, BENEFITS_SCENE.ROADSIDE);

  const i18n = {
    newBenefits: 'upgradePlan.newBenefits.title',
    newPrice: 'upgradePlan.newPrice.title',
    refundCurrentCredit: 'upgradePlan.currentCredit.title',
    refundAmountDue: 'upgradePlan.amountDue.title',
  };

  const label = price.toFixed(2);

  return (
    <>
      <div className="text_2">
        <Translate resourceKey={i18n.newPrice} /> <span data-cy="upgrade-new-price">${label}</span>
      </div>
      <div className="inset-xs" />
      <div className="text_2">
        <Translate resourceKey={i18n.refundCurrentCredit} /> <span data-cy="upgrade-current-credit">$0</span>
      </div>
      <div className="inset-xs" />
      <div className="text_2">
        <strong>
          <Translate resourceKey={i18n.refundAmountDue} /> <span data-cy="upgrade-amount-due">${label}</span>
        </strong>
      </div>
      <div className="inset-m" />

      <h3 className="text-heading_3" data-cy="upgrade-benefits">
        <Translate resourceKey={i18n.newBenefits} />
      </h3>
      <div className="text_2">
        <ul className="icon-list icon-list_check">
          {benefits.map((item, index) => (
            <li className="text-lead_3 benefits" key={index}>
              <Translate resourceKey={item} useHtml />
            </li>
          ))}
        </ul>
      </div>

      <div className="inset-m" />
    </>
  );
};

export default RenewMembershipDetails;
