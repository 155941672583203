import React, { useEffect, useState } from 'react';
import { Link, PageProps } from 'gatsby';
import { navigate } from 'gatsby';

import { BannerSettings } from 'src/components/molecule/Banner';
import { Translate } from 'src/components/Translate';
import { useAuthContext } from 'src/components/AuthProvider';
import { Banner } from 'src/components/molecule';
import { PaymentMethod } from 'src/components/organism';
import RenewMembershipDetails from 'src/components/handleMembership/RenewMembershipDetails';
import useMembership from 'src/api/useMembership';
import useActiveProduct from 'src/api/useActiveProduct';
import useDefaultPaymentMethod from 'src/api/useDefaultPaymentMethod';
import useActivateAutoRenew from 'src/api/useActivateAutoRenew';
import useSelectedLevel from 'src/hooks/useSelectedLevel';
import useRedirect from 'src/hooks/useRedirect';
import { SimpleFooter } from 'src/features/footer';

const ConfirmRenewPlan = ({ location }: PageProps) => {
  useRedirect('/my-account/');

  const [level] = useSelectedLevel(1);
  const [banner, setBanner] = useState<BannerSettings>();
  const [submitFormError, setSubmitFormError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isAuthenticated, loading: loadingUser } = useAuthContext();
  const { data: membership, ...membershipQuery } = useMembership();

  const { product } = useActiveProduct();
  const { defaultPaymentMethod, defaultPaymentMethodLoading } = useDefaultPaymentMethod();

  const mileages = product?.mileages;
  const plan = mileages && mileages[level - 1];
  const activateAutoRenew = useActivateAutoRenew(plan?.id);

  const i18n = {
    heading: 'renewPlan.confirm.heading',
    leadText: 'renewPlan.confirm.leadText',
    paymentTitle: 'upgradeMembership.paymentTitle',
    addCreditCard: 'myAccount.billing.addCreditCard.link',
    noDefaultPayment: 'renewPlan.confirm.error.noDefaultPayment',
    bannerError: 'checkout.payment.error.banner',
    confirmButton: 'updateMembership.confirmButton.text',
    cancelButton: 'updateMembership.cancelButton.text',
    bannerSuccess: 'renewPlan.banner.success',
  };

  const message = location.state?.['msg'];

  useEffect(() => {
    if (message) {
      membershipQuery.stopPolling();
      setBanner({ display: true, message, color: 'success' });
    }
  }, [message]);

  const { state = {} } = location;
  const shouldRedirect = !state?.['nav'];
  useEffect(() => {
    if (isAuthenticated) membershipQuery.refetch();
  }, [isAuthenticated]);

  useEffect(() => {
    const unauthenticated = !loadingUser && !isAuthenticated;
    const hasMembership = membership?.hasMembership();

    if (unauthenticated || hasMembership === false) {
      // use === false here will exclude the undefined case
      membershipQuery.stopPolling();
      navigate('/checkout/', { replace: true });
      // Redirect member users that navigated directly
    } else if (shouldRedirect && hasMembership) {
      membershipQuery.stopPolling();
      if (membership?.isOneYearVip() && membership?.willBeCanceled()) {
        navigate('/my-account/renew-plan/');
      } else {
        navigate('/my-account/');
      }
    }
  }, [loadingUser, isAuthenticated, membership]);

  useEffect(() => {
    // Once the updated membership is fetched, redirect and show success banner
    if (membership?.data?.subscription?.status === 'active') {
      membershipQuery.stopPolling();
      setIsSubmitting(false);
      navigate('/my-account/', { state: { msg: i18n.bannerSuccess } });
    }
  }, [membership]);

  const onSubmit = async () => {
    if (!defaultPaymentMethod?.card) {
      setSubmitFormError(i18n.noDefaultPayment);
    } else {
      setIsSubmitting(true);
      setSubmitFormError(null);
      try {
        await activateAutoRenew();
        membershipQuery.startPolling(200);
      } catch (e) {
        setIsSubmitting(false);
        console.log('[renew-plan] error: ', e);
      }
    }
  };

  return (
    <>
      {banner?.display && <Banner banner={banner} setBanner={setBanner} />}
      {isAuthenticated && !defaultPaymentMethodLoading && membership?.hasMembership() && plan && (
        <div className="container container_center page__update_membership">
          <h2 className="text-display_2">
            <Translate resourceKey={i18n.heading} />
          </h2>
          <p className="text_2" data-cy="upgrade-lead-text">
            <Translate resourceKey={i18n.leadText} values={{ current: membership.data?.name, next: plan.name }} />
          </p>

          <div className="inset-s" />
          <legend className="text-heading_4">
            <Translate resourceKey={i18n.paymentTitle} />
          </legend>
          <div className="inset-s" />
          {defaultPaymentMethod?.card ? (
            <PaymentMethod
              card={defaultPaymentMethod?.card}
              onEdit={() =>
                navigate('/my-account/edit-card/', { state: { origin: '/my-account/renew-plan/confirm/' } })
              }
            />
          ) : (
            <Link
              to={'/my-account/edit-card/'}
              state={{ origin: '/my-account/renew-plan/confirm/' }}
              className="button button_secondary"
              data-cy="new-payment-method"
            >
              <Translate resourceKey={i18n.addCreditCard} />
            </Link>
          )}
          <div className="inset-s" />
          <RenewMembershipDetails level={plan?.level} price={plan.price.amount + product.basePrice.amount} />
          <div className="cta_button-group button-group">
            <div className="button-group__item">
              <button
                data-cy="confirm-upgrade-membership"
                className="button button_primary button_primary--large"
                disabled={isSubmitting || defaultPaymentMethodLoading}
                onClick={onSubmit}
              >
                <Translate resourceKey={i18n.confirmButton} />
              </button>
            </div>
            <div className="button-group__item">
              <Link to={'/my-account/'} className="button button_secondary">
                <Translate resourceKey={i18n.cancelButton} />
              </Link>
            </div>
          </div>

          {submitFormError && (
            <fieldset className="form-element__control has-error" data-cy="form-error">
              <div className="form-element__help">
                <Translate resourceKey={submitFormError} />
              </div>
            </fieldset>
          )}
        </div>
      )}
      <div className="inset-l" />

      <SimpleFooter />
    </>
  );
};

export default ConfirmRenewPlan;
